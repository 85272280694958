
















import { Component, Vue } from "vue-property-decorator";
import { Db } from "@/firebase/db";

@Component({})
export default class Thanks extends Vue {
  private token = this.$route.params.id;

  private checkIfValidUUID(str: string) {
    // Regular expression to check if string is a valid UUID
    const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

    return regexExp.test(str);
  }

  private show = false;

  private copyToClipboard(text: string) {
    navigator.clipboard.writeText(text);
    this.show = true;
    setTimeout(() => {
      this.show = false;
    }, 1500);
  }

  private async mounted() {
    if (this.checkIfValidUUID(this.token)) {
      await Db.collection("users").add({
        code: this.token,
      });
    }
  }
}
